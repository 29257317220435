
import Button from "@/components/base/Button"
import { mdiChevronRight, mdiChevronLeft } from "@mdi/js"
export default {
    name: "ButtonBlok",
    components: { Button },
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    icons: {
        mdiChevronRight,
        mdiChevronLeft,
    },
    computed: {
        rel() {
            if (this.blok.back) return undefined
            if (this.blok.rel === "follow") return ""
            return this.blok.rel || null
        },
        link() {
            if (this.blok.back) return undefined
            return this.blok.link
        },
    },
    methods: {
        onClick() {
            if (this.blok.back) {
                this.$router.back()
            }
        },
    },
}
